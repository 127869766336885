<template>
  <div id="courseLessons">
    <v-container class="my-12">
      <v-row v-if="lessons.length > 0">
        <v-col cols="12" md="10" offset-md="1">
          <v-card class="lesson-card pa-2 mb-6">
            <v-list color="transparent" rounded>
              <v-list-item
                v-for="(lesson, i) in lessons"
                :key="i"
                @click="selectLesson(i)"
              >
                <v-list-item-icon v-if="isLessonLive(lesson.startDate)">
                  <v-icon color="primary" size="48">mdi-message-video</v-icon>
                </v-list-item-icon>
                <v-list-item-icon v-if="!isLessonLive(lesson.startDate)">
                  <v-icon color="primary" size="48"
                    >mdi-motion-play-outline</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="title">{{
                    lesson.name
                  }}</v-list-item-title>
                  <v-list-item-subtitle
                    v-if="isLessonLive(lesson.startDate)"
                    class="grey--text mt-1"
                    style="font-size: 0.8rem"
                  >
                    <v-chip x-small color="secondary"
                      ><v-icon>mdi-dot</v-icon> Devam ediyor</v-chip
                    >
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-if="!isLessonLive(lesson.startDate)"
                    class="grey--text"
                    style="font-size: 0.8rem"
                  >
                    {{
                      lesson.startDate.toLocaleString("tr-TR", {
                        dateStyle: "full",
                        timeStyle: "short"
                      })
                    }}
                    tarihinde Zoom üzerinden
                    <span v-if="isLessonDone(lesson.startDate)"
                      >gerçekleştirildi.</span
                    >
                    <span v-else-if="!isLessonDone(lesson.startDate)"
                      >gerçekleştirilecek.</span
                    >
                  </v-list-item-subtitle>
                  <div v-if="lesson.desc" v-html="lesson.desc" class="mt-2" />
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>

          <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <v-card :color="dialogBgColor" tile>
              <v-toolbar :dark="dialogDark" color="transparent" elevation="0">
                <v-btn icon :dark="dialogDark" @click="closeDialog">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{ selectedItem.name }}</v-toolbar-title>
              </v-toolbar>

              <lesson-video
                v-if="selectedItem.type == 'video'"
                :lesson="selectedItem"
                :course="course"
                :courseRegistration="courseRegistration"
              />
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogOpenZoom" width="400">
            <v-card>
              <v-card-title class="grey lighten-2 mb-6">Dersi Aç</v-card-title>
              <v-card-text
                >{{ this.selectedItem.name }} isimli Zoom toplantısına
                yönlendirileceksiniz.</v-card-text
              >
              <v-divider />
              <v-card-actions>
                <v-spacer />
                <v-btn text @click="dialogOpenZoom = false">İPTAL ET</v-btn>
                <v-btn
                  text
                  color="secondary"
                  :href="this.selectedItem.zoomLink"
                  target="_blank"
                  @click="dialogOpenZoom = false"
                  >DEVAM ET</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" md="6" offset-md="3" class="my-12">
          <div class="msg">
            <v-icon size="60" class="mb-4" color="secondary">info</v-icon>
            <p>Bu eğitim kapsamında yayınlanmış bir ders bulunmamaktadır.</p>
            <p class="pt-6">
              <v-btn
                rounded
                large
                :to="{ name: 'learner-home' }"
                color="primary"
                >GERİ DÖN</v-btn
              >
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";
import LessonVideo from "./Video.vue";

export default {
  metaInfo: () => ({
    title: "Dersler"
  }),

  components: {
    LessonVideo
  },

  props: {
    course: {
      type: Object,
      required: true
    },

    courseRegistration: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      lessons: [
        {
          id: "",
          courseId: "",
          desc: "",
          name: "",
          zoomLink: "",
          youtubeId: "",
          startDate: null
        }
      ],
      panel: [0],
      dialog: false,
      dialogBgColor: "#000",
      dialogDark: true,
      selectedItem: {},
      dialogOpenZoom: false
    };
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    }
  },
  watch: {
    dialog: function(newDialog) {
      if (newDialog == false) {
        this.selectedItem = {};
      } else {
        this.dialogBgColor = "#000";
        this.dialogDark = true;
      }
    }
  },
  methods: {
    async getModules() {
      this.lessons = [];

      // Modülleri al
      const qs1 = await firebase
        .firestore()
        .collection("/apps/kahev-akademi/lessons")
        .where("courseId", "==", this.course.id)
        .where("disabled", "==", false)
        .orderBy("startDate")
        .get();

      qs1.forEach(doc => {
        if (doc.exists) {
          // Modül bilgileri
          const newLesson = {
            id: doc.id,
            courseId: doc.data().courseId,
            desc: doc.data().desc,
            name: doc.data().name,
            zoomLink: doc.data().zoomLink,
            youtubeId: doc.data().youtubeId,
            vimeoId: doc.data().vimeoId,
            startDate: doc.data().startDate.toDate()
          };

          this.lessons.push(newLesson);
        }
      });
    },

    selectLesson(i) {
      this.selectedItem = this.lessons[i];

      // Zoom dersi canlı ise
      if (this.isLessonLive(this.selectedItem.startDate)) {
        // Ders linkini kontrol et
        if (this.selectedItem.zoomLink) {
          // Zoom'a yönlendir
          this.dialogOpenZoom = true;
        } else {
          this.$notify({
            type: "error",
            title: "Hata!",
            text:
              "Ders bağlantısı bulunaması. Lütfen bizimle iletişime geçiniz."
          });
        }

        return;
      }

      // Ders yapıldı mı?
      if (this.isLessonDone(this.selectedItem.startDate)) {
        // Ders kaydı var mı?
        if (this.selectedItem.vimeoId || this.selectedItem.youtubeId) {
          // Videoyu oynat
          this.selectedItem.type = "video";
          this.dialog = true;
        } else {
          // Ders kaydı yüklenmemiş
          this.$notify({
            title: "Dikkat!",
            text:
              "Ders kaydı henüz yüklenmemiş. Lütfen daha sonra tekrar deneyiniz."
          });
        }
      } else {
        // Dersin başlangıç tarihini hatırlat
        this.$notify({
          title: "Dikkat!",
          text: `Bu ders ${this.selectedItem.startDate.toLocaleString("tr-TR", {
            dateStyle: "full",
            timeStyle: "short"
          })} tarihinde gerçekleştirilecektir.`
        });
      }
    },

    closeDialog() {
      this.dialog = false;
    },

    isLessonDone(startDate) {
      const now = firebase.firestore.Timestamp.now().toDate();
      return now < startDate ? false : true;
    },

    isLessonLive(startDate) {
      // Zoom'a erişilebilecek zaman aralığını hesapla
      const now = firebase.firestore.Timestamp.now().toDate();
      const date1 = new Date(startDate.getTime() - 15 * 60000); // 15dk önce
      const date2 = new Date(startDate.getTime() + 180 * 60000); // 180dk sonra
      return now >= date1 && now <= date2;
    }
  },

  async mounted() {
    await this.getModules();
  }
};
</script>

<style lang="scss" scoped>
#courseLessons {
  .lesson-card {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    transition: all ease 0.3s;
    background-color: $color3;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .msg {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    text-align: center;
    background-color: #fff;
    padding: 30px;
    margin-top: 10px;
  }
}
</style>
