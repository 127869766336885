<template>
  <div id="player">
    <iframe
      :src="videoSrc"
      :title="this.lesson.name"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
import Vue from "vue";

// TODO: Video açıldığında ders tamamlama bilgisi arttır.

export default Vue.extend({
  props: {
    lesson: {
      type: Object,
      required: true
    },
    course: {
      type: Object,
      required: true
    },
    courseRegistration: {
      type: Object,
      required: true
    }
  },

  computed: {
    videoSrc() {
      let returnVal = "";

      if (this.lesson.vimeoId) {
        returnVal = `https://player.vimeo.com/video/${this.lesson.vimeoId}`;
      } else if (this.lesson.youtubeId) {
        returnVal = `https://www.youtube.com/embed/${this.lesson.youtubeId}`;
      }

      return returnVal;
    }
  }
});
</script>

<style lang="scss">
#player {
  position: absolute;
  width: 100%;
  height: calc(100% - 64px);
  overflow: hidden;

  & > iframe {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: calc(100% - 64px) !important;
  }
}
</style>
